import React from 'react';

import Layout from 'components/Layout';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import ArticleIndex from 'components/pages/article';
import SEO from 'components/SEO';
import { graphql, HeadFC } from 'gatsby';
import { ArticleQueryResult } from './types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import { ArticlePageId } from 'components/types';

interface NewsroomArticleTemplateProps {
  pageContext: {
    slug: string;
    title: string;
    description: string;
    cover: string;
    seo: null | {
      metaTitle: string;
      metaDescription: string;
      preventIndexing: boolean;
      canonicalURL: string;
    };
  };
  data: ArticleQueryResult;
}
const NewsroomArticleTemplate = ({ data }: NewsroomArticleTemplateProps) => {
  return (
    <LanguageProvider messages={translation}>
      <Layout headerStyle="light">
        <ArticleIndex
          article={data.allStrapiArticle.nodes[0]}
          pageId={ArticlePageId.NEWSROOM}
        />
      </Layout>
    </LanguageProvider>
  );
};
export default NewsroomArticleTemplate;

export const Head = ({
  pageContext: { title, description, cover, seo, slug },
}: HeadFC & NewsroomArticleTemplateProps) => (
  <SEO
    pageTitle={seo?.metaTitle ?? title}
    description={seo?.metaDescription ?? description}
    ogImg={cover}
    isArticle={true}
    preventIndexing={seo?.preventIndexing}
    canonicalURL={`${PREFIX_CANONICAL_URL}/newsroom/${slug}`}
  />
);

export const query = graphql`
  query ($slug: String) {
    allStrapiArticle(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        publishedAt
        updatedAt
        description
        cover {
          alternativeText
          url
          formats {
            small {
              url
            }
          }
        }
        author {
          name
          strapi_id
        }
        blocks {
          body {
            data {
              body
            }
          }
        }
        category {
          name
        }
      }
    }
  }
`;
